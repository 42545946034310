<div class="container-fluid cs-header bg-info bg-gradient-primary-to-secondary">
    <div class="pt-lg-5 pb-lg-5 pt-4 pb-4 page-header-dark">
        <div class="cs-header-icon"></div>
        <h3 class="cs-header-title text-white">{{title}}</h3>
        <div class="cs-header-subtitle"> </div>
    </div>

</div>
<h3 class="cs-header-title text-white">{{title}}</h3>
<!-- <sb-breadcrumbs *ngIf="!hideBreadcrumbs"></sb-breadcrumbs> -->
