import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouteControllerService } from 'src/app/core/helpers/route-controller.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { UserService } from 'src/app/core/services/navigation/user.service';
import { TenantConfigService } from 'src/app/core/services/tenant-config-services/tenant-config.service';
import { SideNavItems, SideNavSection } from 'src/app/shared/models/navigation.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'sb-side-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './side-nav.component.html',
    styleUrls: ['side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
    @Input() sidenavStyle!: string;
    @Input() sideNavItems!: SideNavItems;
    @Input() sideNavSections!: SideNavSection[];

    subscription: Subscription = new Subscription();
    routeDataSubscription!: Subscription;
    selectedLink: string;
    basePath = "";

    constructor(public navigationService: NavigationService, public userService: UserService,
                public routeControllerService:RouteControllerService,@Inject(DOCUMENT) private document: Document,
                private tenantService : TenantConfigService) {
                    this.tenantService.getTenant().subscribe(tenant => {
                        this.basePath = tenant.basePath;
                    });
                }

    ngOnInit() {
        this.selectedLink='home';
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    navigate(path) {
        //console.log(path);
        // debugger;
        if(path == "centralPortal") {
            this.document.location.href = environment.centralPortalUrl + this.basePath;
        }else{
            this.selectedLink = path;
            this.routeControllerService.navigate(path);
        } 
    }
}
