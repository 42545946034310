import { Injectable } from '@angular/core';
import { Auth } from 'src/app/core/services/auth-services/models/auth.model';
import * as jwt_decode from 'jwt-decode';
import { CookieServices } from '../cookie-service/cookieService';
import { TenantAPIHandler } from '../../api/handlers/tenant.api.handler';
import { TenantConfig } from '../tenant-config-services/models/tenant-config.model';
import { AuthAPIHandler } from '../../api/handlers/auth.handler';
import { AuthStore } from './stores/auth.store.service';
import { AuthUser } from './models/auth.user.model';
import { PermissionService } from '../permission-service/permission.service';
import { Roles } from 'src/app/shared/enums/roles';
@Injectable({
    providedIn: 'root'
  })
export class AuthService extends AuthStore {

    constructor(private cookieService: CookieServices,
                private permissionService: PermissionService,
                private authAPIHandler: AuthAPIHandler) {
        super();
    }

    authenticate(): Promise<any> {
        //console.log('authenticate');
        return new Promise((resolve, reject) => {
                return this.authAPIHandler.verifyUser()
                .then((user: AuthUser) => {
                    this.setAuthUser(user);
                    this.permissionService.setPermissionsKeys(user.permissions);
                    if(!this.permissionService.HasApplicationAccess() || user['custom:role'] == Roles.Student) {
                        reject(null);
                    }else{
                        resolve(user);
                    }
                }).catch(err => {
                    //console.log(err);
                    reject(null);
                });
        });
    }

    get accessToken() {
        return this.cookieService.getAccessToken;
    }

    get refreshToken() {
        return this.cookieService.getRefreshToken;
    }

    isLoggedIn() {
       return this.cookieService.isAuthenticated();
    }
    
    setAuthUser(auth: AuthUser) {
        return super.setCompleteState(auth);
    }
    getAuthUser() {
        
        return super.authUser;
    }

}