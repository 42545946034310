import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor } from './core/api/interceptor/api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { ErrorComponent } from './components/error/error.component';
import { TenantConfigService } from './core/services/tenant-config-services/tenant-config.service';
import { MainComponent } from './components/main/main.component';
import { CommonModule } from '@angular/common';
import { AuthService } from './core/services/auth-services/auth.service';
import { AuthAPIHandler } from './core/api/handlers/auth.handler';
import { TenantAPIHandler } from './core/api/handlers/tenant.api.handler';
import { CookieServices } from './core/services/cookie-service/cookieService';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ErrorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, TenantConfigService,AuthService,TenantAPIHandler,AuthAPIHandler,CookieServices
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
