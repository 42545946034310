import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { ApiConfigs, replacePathValue, RouteParams } from 'src/app/shared/constants/api-configs';
import { HttpStatus } from 'src/app/shared/enums/http-status.enum';
import { ResponseMessageKeys } from 'src/app/shared/enums/response-codes.enum';
import { ResponseStatus } from 'src/app/shared/enums/response-status.enum';
import { BaseResponse } from 'src/app/shared/models/base-response.model';
import { TenantCofigurationsResponse } from 'src/app/shared/models/req-res-models/tenant-configuration.model';

@Injectable()
export class TenantAPIHandler {

    constructor(private apiService: ApiService) { }

    getTenantConfigurations(tenantBasePath: string): Promise<TenantCofigurationsResponse> {

        return new Promise((resolve, reject) => {
            // set routes paths
            const path = replacePathValue(ApiConfigs.TenantConfigurations,RouteParams.TenantBasePath,tenantBasePath);
            //const path = replacePathValue(ApiConfigs.TenantConfigurations,RouteParams.TenantBasePath,tenantBasePath);

         
            return this.apiService.get(path).subscribe(
                (res: HttpResponse<BaseResponse<TenantCofigurationsResponse>>) => {
                    if (res.status === HttpStatus.Success) {
                        if(res.body.messageKey == ResponseMessageKeys.TenantNotFound){
                            reject(null);
                        }else{
                            resolve(res.body.data);
                        }
                    }
                },
                (res: HttpErrorResponse) => {
                    //console.log(res)
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                            reject(null);
                    }
                });
            });
    }
}