import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { rejects } from 'assert';
import { ApiService } from 'src/app/core/api/api.service';
import { ApiConfigs, replacePathValue, RouteParams } from 'src/app/shared/constants/api-configs';
import { HttpStatus } from 'src/app/shared/enums/http-status.enum';
import { ResponseMessageKeys } from 'src/app/shared/enums/response-codes.enum';
import { ResponseStatus } from 'src/app/shared/enums/response-status.enum';
import { BaseResponse } from 'src/app/shared/models/base-response.model';
import { TenantCofigurationsResponse } from 'src/app/shared/models/req-res-models/tenant-configuration.model';
import { Auth } from '../../services/auth-services/models/auth.model';
import { AuthUser } from '../../services/auth-services/models/auth.user.model';

@Injectable()
export class AuthAPIHandler {

    constructor(private apiService: ApiService) { }

    authenticate(auth: Auth): Promise<Auth> {
        return new Promise((resolve, reject) => {
            
            // set routes paths
            const path = ApiConfigs.AuthRequest;
            return this.apiService.post(path,auth).subscribe(
                (res: HttpResponse<BaseResponse<Auth>>) => {
                    if (res.status === HttpStatus.Success) {
                        resolve(res.body.data);
                    }else{
                            reject(null);
                    }
                },(res: HttpErrorResponse) => {
                    //console.log(res)
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                            reject(null);
                    }
            });
        });
    }

    verifyUser(): Promise<AuthUser> {
        //console.log('verifyUser');
        return new Promise((resolve, reject) => {
            const path = ApiConfigs.VerifyUserRequest;
            return this.apiService.get(path).subscribe(
                (res: HttpResponse<BaseResponse<Auth>>) => {
                    if (res.status === HttpStatus.Success) {
                        const authUser = new AuthUser();
                        authUser.permissions = res.body.data['custom:permissions'];
                        authUser.userId = res.body.data['custom:userId'];
                        authUser.role = res.body.data['custom:role'];
                        authUser.name = res.body.data['name'];
                        authUser.phoneNumber = res.body.data['phoneNumber'];
                        authUser.email = res.body.data['email'];
                        resolve(authUser);
                    } else {
                        reject(null);
                    }
                }, (res: HttpErrorResponse) => {
                    //console.log(res)
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                        reject(null);
                    }
                });
        });
    }
}