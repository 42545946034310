<ng-container *ngIf="sideNavItem">

    <a class="nav-link" (click)="navigate(sideNavItem.link)" [ngClass]="{active: isActive, collapsed: !expanded}">
        <div class="sb-nav-link-icon" *ngIf="sideNavItem.icon">
            <em class="{{sideNavItem.icon}}"></em>
            <!-- <fa-icon [icon]='["fas", sideNavItem.icon]'></fa-icon> -->
        </div>
        {{sideNavItem.text}}
        <div class="sb-sidenav-collapse-arrow">
            <em class="bx bxs-chevron-down" *ngIf="sideNavItem.submenu"></em>
            <!-- <fa-icon class="ml-auto" *ngIf="sideNavItem.submenu" [icon]="['fas', 'angle-down']"></fa-icon> -->
        </div>
    </a>
</ng-container>
<nav class="sb-sidenav-menu-nested nav" *ngIf="sideNavItem?.submenu &amp;&amp; expanded">
    <sb-side-nav-item class="submenu" *ngFor="let submenuItem of sideNavItem?.submenu" [sideNavItem]="submenuItem">
    </sb-side-nav-item>
</nav>
