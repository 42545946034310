import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
  ) { }

  public get(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl,{ observe: 'response' }).pipe(
      map(response => {
        return response;
      }),
      catchError(err => this.handleError(err, 'get')));
  }

  public post(apiUrl: string, requestObject: {}): Observable<any> {
    // headerType[headerKeys.authorization] = this.authService.authorizationHeaderValue;
    return this.http.post(apiUrl, requestObject, { observe: 'response' }).pipe(
      map(response => {
        return response;
      }),
      catchError(err => this.handleError(err, 'post')));
  }

  public put(apiUrl: string, requestObject: {}): Observable<any> {
    // headerType[headerKeys.authorization] = this.authService.authorizationHeaderValue;
    return this.http.put(apiUrl, requestObject, { observe: 'response' }).pipe(
      map(response => {
        return response;
      }),
      catchError(err => this.handleError(err, 'put')));
  }

  public delete(apiUrl: string): Observable<any> {
    // headerType[headerKeys.authorization] = this.authService.authorizationHeaderValue;
    return this.http.delete(apiUrl, { observe: 'response' }).pipe(
      map(response => {
        return response;
      }),
      catchError(err => this.handleError(err, 'delete')));
  }

  public postWithoutAuth(apiUrl: string, requestObject: {}): Observable<any> {
    // headerType[headerKeys.authorization] = this.authService.authorizationHeaderValue;
    // const header = new HttpHeaders(headerType);
    return this.http.post(apiUrl, requestObject, { observe: 'response' }).pipe(
      map(response => {
        return response;
      }),
      catchError(err => this.handleError(err, 'post')));
  }

  private handleError(errorResponse: Response | any, requestType?: string) {
    // if (errorResponse.status === 500 && requestType === requestTypes.get){
    //   this.authorizationService.setErrorType({errorType: errorTypes.internalServerError});
    //   this.actionAlertService.changeAlertStatus({isLoading: false});
    // } else if (errorResponse.status === 500 && requestType === requestTypes.post){
    //   this.actionAlertService.changeAlertStatus({isLoading: false, message: 'Exception.InternalServerError', type: 'error', summary: 'Error'});
    // } else if (errorResponse.status === 400 && requestType === requestTypes.get){
    //   this.authorizationService.setErrorType({errorType: errorTypes.invalidRequest, errorCode: errorResponse.error.code, errorKey: errorResponse.error.messageKey});
    //   this.actionAlertService.changeAlertStatus({isLoading: false});
    // } else if (errorResponse.status === 400 && requestType === requestTypes.post){
    //   this.actionAlertService.changeAlertStatus({isLoading: false, message: errorResponse.error.messageKey, type: 'error', summary: 'Error'});
    // } else if (errorResponse.status === 401){
    //   this.authorizationService.setErrorType({errorType: errorTypes.accessDenied});
    //   this.actionAlertService.changeAlertStatus({isLoading: false});
    // }
    return throwError(errorResponse);
  }
}
