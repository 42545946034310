import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActionAlert } from 'src/app/shared/models/action-alert.model';
import { AlertStore as ActionAlertStore } from './stores/action-alert.store';

@Injectable({
  providedIn: 'root'
})
export class ActionAlertService extends ActionAlertStore {

  constructor() {
    super();
   }

  changeAlertStatus(status: ActionAlert){
    super.setCompleteState(status);
  }

  clearMessagers(){
    this.clearStore();
  }

}