import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth-services/auth.service';
import { TenantConfigService } from '../services/tenant-config-services/tenant-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private basePath: string;
  private clientId: string;
  constructor(private router: Router, private authService: AuthService, private tenantConfigService: TenantConfigService,
    @Inject(DOCUMENT) private document: Document) {
    tenantConfigService.getTenant().subscribe(tenant => {
      this.clientId = tenant.clientId;
      this.basePath = tenant.basePath;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = this.authService.isLoggedIn();
    return new Promise((resolve) => {

      const tenantName = route.paramMap.get('tenant');
      //console.log(tenantName);
      if (tenantName === null) {
         this.document.location.href = environment.centralPortalUrl;
         resolve(false);
      } else if (isLoggedIn && this.basePath != null && this.clientId != null) {
        resolve(true);
      } else {
        if (tenantName) {
          return this.tenantConfigService.ValidateTenant(tenantName).then(data => {
            return this.authService.authenticate().then(user => {
              resolve(true);
            }).catch(err => {
              resolve(false);
            });
          }).catch(notValid => {
            this.document.location.href = environment.centralPortalUrl;
            resolve(false);
          });
        } else {
          this.document.location.href = environment.centralPortalUrl;
        }
      }
    });
  }

}