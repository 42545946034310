import { Component } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TenantConfigService } from './core/services/tenant-config-services/tenant-config.service';
import { AuthService } from './core/services/auth-services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isValidTenant: boolean;
  public tenant: string;
  title = 'class-frontend';
  tenantLogo = '';
  public loading: boolean = true;
  public favIcon: HTMLLinkElement = document.querySelector('#favIcon');

  constructor(private router: Router, private route: ActivatedRoute,private http: HttpClient,private tenantService: TenantConfigService,
    private authService: AuthService) {
    
    this.isValidTenant = false;
    this.tenant = this.route.snapshot.paramMap.get('tenant');
    router.events.subscribe((routerEvent: RouterEvent) => {
      this.checkRouterEvent(routerEvent);
    });
    this.tenantService.getTenant().subscribe(tenant => {
      this.tenantLogo = tenant.logo;
      if(tenant.favIcon){
        this.favIcon.href = tenant.favIcon;
      }
      if(tenant.clientId){
        this.isValidTenant = true;
      }
    });
  }

  checkRouterEvent(routerEvent: RouterEvent): void {
    // if (routerEvent instanceof NavigationStart) {
    //   this.loading = true;
    // }
    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.loading = false;
    }
  }

}
