<nav class="sb-topnav navbar navbar-expand">
    <span class="navbar-brand">
        <img alt="" class="navbar-brand-logo" src="{{logo}}">
    </span>
    <!--    <button (click)="toggleSideNav()" class="btn btn-link btn-sm order-1 order-lg-0 btn-transparent-dark"-->
    <!--            data-cy="topNavToggleSideNav"-->
    <!--            id="sidebarToggle"><i class='bx bx-menu'></i>-->
    <!--        &lt;!&ndash;         <fa-icon [icon]='["fas", "bars"]'></fa-icon>&ndash;&gt;-->
    <!--    </button>-->
    <!-- Navbar Search-->
    <div class="d-none d-md-inline-block form-inline  mr-0 mr-md-3 my-2 my-md-0">
        <div class="row ml-0">
            <div class="col-lg-12">
                <div class="form-group search-box">
                    <i class='bx bx-search'></i>
                    <input class="form-control" placeholder="Search" type="text">
                </div>
            </div>
        </div>
    </div>

    <!--       <div class="d-none d-md-inline-block form-inline mx-auto mr-0 mr-md-3 my-2 my-md-0">-->
    <!--        <img src="{{logo}}" height="25" >-->
    <!--        LOGO-->
    <!--       </div>-->
    <!-- Navbar-->
    <ul class="navbar-nav align-items-center ml-auto">

        <!-- Alerts Dropdown-->
        <li class="nav-item">
            <a aria-expanded="false" aria-haspopup="true" class="btn btn-icon btn-transparent-dark"
               href="#" role="button">
                <i class='bx bxs-message-rounded'></i>
            </a> <span>Connect</span>
        </li>
        <li class="nav-item dropdown no-caret d-none d-sm-block mr-2 ml-4 dropdown-notifications">
            <a aria-expanded="false" aria-haspopup="true" class="btn btn-icon btn-transparent-dark dropdown-toggle"
               data-toggle="dropdown" href="#" id="navbarDropdownAlerts" role="button">
                <i class='bx bxs-bell'></i>
            </a>
            <!--            <div aria-labelledby="navbarDropdownAlerts"-->
            <!--                 class="dropdown-menu dropdown-menu-right border-0 shadow animated&#45;&#45;fade-in-up">-->
            <!--                <h6 class="dropdown-header dropdown-notifications-header">-->
            <!--                    <i class='bx bx-bell'></i>-->
            <!--                    Alerts Center-->
            <!--                </h6>-->
            <!--&lt;!&ndash;                &lt;!&ndash; Example Alert 1&ndash;&gt;&ndash;&gt;-->
            <!--&lt;!&ndash;                <a class="dropdown-item dropdown-notifications-item" href="#!">&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="dropdown-notifications-item-icon bg-warning">&ndash;&gt;-->
            <!--&lt;!&ndash;                        <i class='bx bx-bell'></i>&ndash;&gt;-->
            <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="dropdown-notifications-item-content">&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div class="dropdown-notifications-item-content-details">December 29, 2020</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div class="dropdown-notifications-item-content-text">This is an alert message. It's nothing&ndash;&gt;-->
            <!--&lt;!&ndash;                            serious, but it requires your attention.&ndash;&gt;-->
            <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                </a>&ndash;&gt;-->
            <!--&lt;!&ndash;                &lt;!&ndash; Example Alert 2&ndash;&gt;&ndash;&gt;-->
            <!--&lt;!&ndash;                <a class="dropdown-item dropdown-notifications-item" href="#!">&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="dropdown-notifications-item-icon bg-info">&ndash;&gt;-->
            <!--&lt;!&ndash;                        <i class='bx bx-bell'></i>&ndash;&gt;-->
            <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="dropdown-notifications-item-content">&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div class="dropdown-notifications-item-content-details">December 22, 2020</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div class="dropdown-notifications-item-content-text">A new monthly report is ready. Click here&ndash;&gt;-->
            <!--&lt;!&ndash;                            to view!&ndash;&gt;-->
            <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                </a>&ndash;&gt;-->

            <!--&lt;!&ndash;                <a class="dropdown-item dropdown-notifications-footer" href="#!">View All Alerts</a>&ndash;&gt;-->
            <!--            </div>-->
        </li>
        <li class="nav-item">
            <p-menu #menu [hideTransitionOptions]="'0ms'" [model]="user_items" [popup]="true"
                    [showTransitionOptions]="'0ms'"></p-menu>
            <a (click)="menu.toggle($event)" aria-expanded="false" aria-haspopup="true"
               class="btn btn-icon btn-transparent-dark" role="button">
                <i class='bx bxs-user'></i>
            </a>
        </li>
        <li class="nav-item">
            <a aria-expanded="false" aria-haspopup="true" class="btn btn-icon btn-transparent-dark"
               role="button">
                <i class='bx bx-dots-vertical-rounded'></i>
            </a>
        </li>
        <!-- Messages Dropdown-->
        <!--        <li class="nav-item dropdown no-caret d-none d-sm-block mr-3 dropdown-notifications">-->
        <!--            <a aria-expanded="false" aria-haspopup="true" class="btn btn-icon btn-transparent-dark dropdown-toggle"-->
        <!--               data-toggle="dropdown" href="javascript:void(0);" id="navbarDropdownMessages" role="button">-->
        <!--                <i class='bx bx-envelope'></i>-->
        <!--            </a>-->
        <!--            <div aria-labelledby="navbarDropdownMessages"-->
        <!--                 class="dropdown-menu dropdown-menu-right border-0 shadow animated&#45;&#45;fade-in-up">-->
        <!--                <h6 class="dropdown-header dropdown-notifications-header">-->
        <!--                    <i class='bx bx-envelope'></i>-->
        <!--                    Message Center-->
        <!--                </h6>-->
        <!--                &lt;!&ndash; Example Message 1  &ndash;&gt;-->
        <!--                <a class="dropdown-item dropdown-notifications-item" href="#!">-->
        <!--                    &lt;!&ndash; <img class="dropdown-notifications-item-img" src="assets/img/illustrations/profiles/profile-2.png"> &ndash;&gt;-->
        <!--                    <div class="dropdown-notifications-item-content">-->
        <!--                        <div class="dropdown-notifications-item-content-text">Lorem ipsum dolor sit amet, consectetur-->
        <!--                            adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim-->
        <!--                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo-->
        <!--                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu-->
        <!--                            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui-->
        <!--                            officia deserunt mollit anim id est laborum.-->
        <!--                        </div>-->
        <!--                        <div class="dropdown-notifications-item-content-details">Thomas Wilcox · 58m</div>-->
        <!--                    </div>-->
        <!--                </a>-->
        <!--                &lt;!&ndash; Example Message 2&ndash;&gt;-->
        <!--                <a class="dropdown-item dropdown-notifications-item" href="#!">-->
        <!--                    &lt;!&ndash; <img class="dropdown-notifications-item-img" src="assets/img/illustrations/profiles/profile-3.png"> &ndash;&gt;-->
        <!--                    <div class="dropdown-notifications-item-content">-->
        <!--                        <div class="dropdown-notifications-item-content-text">Lorem ipsum dolor sit amet, consectetur-->
        <!--                            adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim-->
        <!--                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo-->
        <!--                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu-->
        <!--                            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui-->
        <!--                            officia deserunt mollit anim id est laborum.-->
        <!--                        </div>-->
        <!--                        <div class="dropdown-notifications-item-content-details">Emily Fowler · 2d</div>-->
        <!--                    </div>-->
        <!--                </a>-->
        <!--                &lt;!&ndash; Example Message 3&ndash;&gt;-->
        <!--                <a class="dropdown-item dropdown-notifications-item" href="#!">-->
        <!--                    &lt;!&ndash; <img class="dropdown-notifications-item-img" src="assets/img/illustrations/profiles/profile-4.png"> &ndash;&gt;-->
        <!--                    <div class="dropdown-notifications-item-content">-->
        <!--                        <div class="dropdown-notifications-item-content-text">Lorem ipsum dolor sit amet, consectetur-->
        <!--                            adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim-->
        <!--                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo-->
        <!--                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu-->
        <!--                            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui-->
        <!--                            officia deserunt mollit anim id est laborum.-->
        <!--                        </div>-->
        <!--                        <div class="dropdown-notifications-item-content-details">Marshall Rosencrantz · 3d</div>-->
        <!--                    </div>-->
        <!--                </a>-->
        <!--                &lt;!&ndash; Example Message 4&ndash;&gt;-->
        <!--                <a class="dropdown-item dropdown-notifications-item" href="#!">-->
        <!--                    &lt;!&ndash; <img class="dropdown-notifications-item-img" src="assets/img/illustrations/profiles/profile-5.png"> &ndash;&gt;-->
        <!--                    <div class="dropdown-notifications-item-content">-->
        <!--                        <div class="dropdown-notifications-item-content-text">Lorem ipsum dolor sit amet, consectetur-->
        <!--                            adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim-->
        <!--                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo-->
        <!--                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu-->
        <!--                            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui-->
        <!--                            officia deserunt mollit anim id est laborum.-->
        <!--                        </div>-->
        <!--                        <div class="dropdown-notifications-item-content-details">Colby Newton · 3d</div>-->
        <!--                    </div>-->
        <!--                </a>-->
        <!--                &lt;!&ndash; Footer Link&ndash;&gt;-->
        <!--                <a class="dropdown-item dropdown-notifications-footer" href="#!">Read All Messages</a>-->
        <!--            </div>-->
        <!--        </li>-->
        <!-- User Dropdown-->
      
    </ul>

    <!--   following ng-container was Commented by dasun-->
    <!--    <ng-container>-->

    <!--        <div class="ce-main-nav-user">-->
    <!--          <div class="dropdown" >-->
    <!--            <button type="button" class="btn btn-link" aria-haspopup="true" (click)="status=!status">-->
    <!--              <i class='bx bx-user-circle bx-sm align-middle mr-1'></i> <span class="ce-link"></span> <i-->
    <!--                class='bx bxs-chevron-down align-middle'></i>-->
    <!--            </button>-->
    <!--            <div class="dropdown-menu dropdown-menu-right mt-3" [ngClass]="status ? 'show' : ''">-->
    <!--              &lt;!&ndash; <span class="dropdown-item ce-link" translate>UserSettingsMenu.UserSettings</span> &ndash;&gt;-->
    <!--              <span class="dropdown-item ce-link" (click)="signout()" translate>Log out</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </ng-container> -->
</nav>
