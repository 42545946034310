import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// /* Module */
// import { NavigationModule } from './modules/navigation/navigation.module';

/* Containers */
import * as navigationContainers from './modules/navigation/containers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* primeng */
import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NavigationModule } from './modules/navigation/navigation.module';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AlertComponent } from './components/alert/alert.component';
import { MessageService } from 'primeng/api';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ChartModule } from 'primeng/chart';
import { EditorModule } from 'primeng/editor';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { StepsModule } from 'primeng/steps';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {BlockUIModule} from 'primeng/blockui';
export const PrimeNg = [
    InputSwitchModule,
    DropdownModule,
    CalendarModule,
    InputMaskModule,
    TableModule,
    PanelModule,
    SplitButtonModule,
    TabViewModule,
    CardModule,
    ButtonModule,
    InputTextareaModule,
    DialogModule,
    DynamicDialogModule,
    ToastModule,
    ChartModule,
    EditorModule,
    ScrollPanelModule,
    RadioButtonModule,
    InputNumberModule,
    StepsModule,
    ProgressSpinnerModule,
    BlockUIModule,
    DynamicDialogModule
];

@NgModule({
    declarations: [
        AlertComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NavigationModule,
        ...PrimeNg,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        NavigationModule,
        TableModule,
        AlertComponent,
        ...PrimeNg,

    ],
    providers: [
        MessageService
    ]
})
export class SharedModule { }
