export enum ResponseMessageKeys {
    TenantNotFound = "TENANT_NOT_FOUND",
    AutheticateSuccess= 'AUTHETICATE_SCUCCESS',
    InvalidPasswordOrUsername = 'INVALID_PASSWORD_OR_USERNAME',
    ClassCreateSuccess = 'CLASS_CREATED',
    ActivityDeleted = 'ACTIVITY_DELETED',
    ActivityArchived = 'ACTIVITY_ARCHIVED',   
    ActivityVisibiltyChanged = 'VISIBILITY_CHANGED',   
     
}
