import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/gaurds/auth.gaurd';
import { ErrorComponent } from './components/error/error.component';
import { MainComponent } from './components/main/main.component';

const routes: Routes = [
  
    {
        path: ':tenant', canActivate: [AuthGuard], component: MainComponent,
        children: [
            
            {
                path: 'home',
                loadChildren: () => import('./components/main/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'marking',
                loadChildren: () => import('./components/main/marking/marking.module').then(m => m.MarkingsModule)
            },
            {
                path: 'payments',
                loadChildren: () => import('./components/main/payments/payments.module').then(m => m.PaymentsHubModule)
            },
            {
                path: 'material-hub',
                loadChildren: () => import('./components/main/material-hub/material-hub.module').then(m => m.MaterialHubModule)
            },
            {
                path: 'reports',
                loadChildren: () => import('./components/main/reports/reports.module').then(m => m.ReportsModule)
            },
            {
                path: 'insights',
                loadChildren: () => import('./components/main/insights/insights.module').then(m => m.InsightsModule)
            },
            {
                path: 'video-library',
                loadChildren: () => import('./components/main/video-library/video-library.module').then(m => m.VideoLibraryModule)
            },
            {
                path: 'archives',
                loadChildren: () => import('./components/main/archives/archives.module').then(m => m.ArchivesModule)
            },
            {
                path: 'user-profile',
                loadChildren: () => import('./components/main/user-profile/user-profile.module').then(m => m.UserProfileModule)
            },
            {
                path: '',
                loadChildren: () => import('./components/main/home/home.module').then(m => m.HomeModule)
            },
            {
                path: '**',
                loadChildren: () => import('./components/main/home/home.module').then(m => m.HomeModule)
            }]
    },
    {
        path: '', canActivate: [AuthGuard], component: ErrorComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
