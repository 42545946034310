export enum PermissionIds {
    PS,
    PT,
    P5,
    P5S1,
    P5S2,
    P6,
    P6S1,
    P6S2,
    P6S3,
    P7,
    P7S1,
    P7S2,
    P7S3,
    P7S4,
    P7S5,
    P7S6,
    P8,
    P8S1,
    P8S2,
    P8S3,
    P8S4,
    P8S5,
    P9,
    P9S1,
    P9S2,
    P9S3,
    P10,
    P10S1,
    P10S2,
    P11,
    P11S1,
    P11S2,
    P11S6
}