import { ThrowStmt } from '@angular/compiler';
import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth-services/auth.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { sideNavItems, sideNavSections } from 'src/app/shared/constants/side-nav.data';
import { Roles } from 'src/app/shared/enums/roles';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @Input() static = false;
  @Input() light = false;
  @HostBinding('class.sb-sidenav-toggled') sideNavHidden = false;
  subscription: Subscription = new Subscription();
  sideNavItems = sideNavItems;
  sideNavSections = sideNavSections;
  sidenavStyle = '';

  constructor(
    public navigationService: NavigationService,
    public authServive: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }
  ngOnInit() {
    if (this.light) {
      this.sidenavStyle = 'sb-sidenav-light';
    }
    this.subscription.add(
      this.navigationService.sideNavVisible$().subscribe(isVisible => {
        this.sideNavHidden = !isVisible;
        this.changeDetectorRef.markForCheck();
      })
    );

    this.authServive.getAuthUser().subscribe(user => {
            if(user.role == Roles.Student || user.role == Roles.Teacher){
              delete this.sideNavItems['centralPortal'];
            }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

