
import { Injectable } from '@angular/core';
import { ActionAlert } from 'src/app/shared/models/action-alert.model';
import { Store } from 'src/app/shared/models/store.model';

@Injectable({
    providedIn: 'root'
})
export class AlertStore {

    private state: Store<ActionAlert>;
    constructor() {
        this.state = new Store<ActionAlert>({
            isLoading: null,
            toast: null,
        });
    }

    public get alertState() {
        return this.state.getState();
    }

    protected setCompleteState(status: ActionAlert) {
        this.state.setState(status);
    }

    protected clearStore(){
        this.state.clearState();
    }


}