import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Messages } from 'src/app/shared/constants/messages-list';
import { TenantConfigService } from '../services/tenant-config-services/tenant-config.service';


@Injectable({
    providedIn: 'root'
})
export class RouteControllerService {
   private tenantName: string;

    constructor(private configFacade: TenantConfigService, private router: Router) {
        // this.configFacade.selectTenantName$.subscribe(tenantName => {
        //     if (tenantName) {
        //         this.tenantName = tenantName;
        //     }
        // });
        this.configFacade.tenantState.subscribe(tenant=>{
            this.tenantName = tenant.basePath;
        });

    }

    navigate(path: string) {
        this.router.navigate([this.tenantName + '/' + path]);
    }
}