import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth-services/auth.service';
import { throwError } from 'rxjs';
import { Auth } from '../../services/auth-services/models/auth.model';
import { CookieServices } from '../../services/cookie-service/cookieService';
import { TenantConfigService } from '../../services/tenant-config-services/tenant-config.service';
import { TenantConfig } from '../../services/tenant-config-services/models/tenant-config.model';
import { DOCUMENT } from '@angular/common';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap } from 'rxjs/operators';
import { ApiConfigs } from 'src/app/shared/constants/api-configs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
    private clientId: string;
    private accessToken: string;
    private basePath: string;
    private centralProtalPath: string;
    constructor(private http: HttpClient, private authService: AuthService, private tenantService: TenantConfigService, private cookieServices: CookieServices,
        @Inject(DOCUMENT) private document: Document) {
        tenantService.getTenant().subscribe(tenant => {
            this.clientId = tenant.clientId;
            this.basePath = tenant.basePath;
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add auth header with jwt if user is logged in and request is to the api url
        const accessToken = this.cookieServices.getAccessToken();

        if (accessToken != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (accessToken == null && this.cookieServices.getRefreshToken().toString() == null) {
                // this.document.location.href = environment.centralPortalUrl;
            }
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                //console.log(error.status);
                if (error.status == 401) {
                    //console.log(error);
                    //console.log('authorize again');
                    if (this.cookieServices.getRefreshToken() != null) {
                        return this.refreshToken().pipe(
                            switchMap((res: HttpResponse<any>) => {
                                //console.log('refresh token authorized', res);
                                if (res.status == 200) {
                                    const accessTk = res.body.data.accessToken;
                                    if (accessTk) {
                                        this.cookieServices.setAccessToken(accessTk);
                                        request = request.clone({
                                            setHeaders: {
                                                Authorization: `Bearer ${accessTk}`
                                            }
                                        });
                                        return next.handle(request);
                                    } else {
                                        this.redirectToCentralPortal();
                                        return EMPTY;
                                    }
                                } else {
                                    this.redirectToCentralPortal();
                                    return EMPTY;
                                }
                            }),
                            catchError((error) => {
                                //console.log('refresh token authorization failed', error)
                                this.redirectToCentralPortal();
                                return EMPTY;
                            })
                        );
                    } else {
                        this.redirectToCentralPortal();
                    }

                }
                return throwError(error);
            }),
            // finalize(() => {
            //     this.hideLoader();
            // })
        );
    }

    private redirectToCentralPortal() {
        this.cookieServices.destroyTokens();
        // this.cookieServices.setRedirectUrl();
        this.centralProtalPath = environment.centralPortalUrl + this.basePath;
        this.document.location.href = this.centralProtalPath;
    }


    private refreshToken() {
        const auth = new Auth();
        auth.refreshToken = this.cookieServices.getRefreshToken() + 21;
        auth.clientId = this.clientId;
        auth.clientSecret = '';
        return this.http.post(ApiConfigs.AuthRequest, auth, { observe: 'response' }).pipe(
            map(response => {
                return response;
            }),
            catchError(err => throwError(err))
        );
    }
}