import { SideNavItems, SideNavSection } from "../models/navigation.model";

export const sideNavSections: SideNavSection[] = [
    // {
    //     text: 'CORE',
    //     items: ['dashboard'],
    // },
    {
        text: 'MANAGE',
        items: ['home', 'assessments', 'payments', 'materials', 'reports', 'insights', 'archives','centralPortal'],
    },
];
export const sideNavItems: SideNavItems = {
    home: {
        icon: 'bx bxs-home',
        text: 'Home',
        link: '/home',
    },
    assessments : {
        icon: 'bx bxs-file-archive',
        text: 'Assessment Hub',
        link: '/marking',
        // submenu: [
        //     {
        //         text: 'List Tenants',
        //         link: '/tenant-managment/',
        //     },
        //     {
        //         text: 'Create Tenant',
        //         link: '/tenant-managment/create-tenant',
        //     },
        // ],
    },
    payments: {
        icon: 'bx  bxs-credit-card',
        text: 'Payment Hub',
        link: '/payment',
        // submenu: [
        //     {
        //         text: 'List Tenants',
        //         link: '/tenant-managment/',
        //     },
        //     {
        //         text: 'Create Tenant',
        //         link: '/tenant-managment/create-tenant',
        //     },
        // ],
    },
    materials: {
        icon: 'bx bxs-dashboard',
        text: 'Material Hub',
        link: '/material-hub',
        // submenu: [
        //     {
        //         text: 'List Tenants',
        //         link: '/tenant-managment/',
        //     },
        //     {
        //         text: 'Create Tenant',
        //         link: '/tenant-managment/create-tenant',
        //     },
        // ],
    },
    reports: {
        icon: 'bx bxs-report',
        text: 'Reports',
        link: '/reports',
        // submenu: [
        //     {
        //         text: 'List Tenants',
        //         link: '/tenant-managment/',
        //     },
        //     {
        //         text: 'Create Tenant',
        //         link: '/tenant-managment/create-tenant',
        //     },
        // ],
    },
    insights: {
        icon: 'bx bxs-pie-chart-alt-2',
        text: 'Insights',
        link: '/insights',
        // submenu: [
        //     {
        //         text: 'List Tenants',
        //         link: '/tenant-managment/',
        //     },
        //     {
        //         text: 'Create Tenant',
        //         link: '/tenant-managment/create-tenant',
        //     },
        // ],
    },
    archives: {
        icon: 'bx bxs-archive',
        text: 'Archives',
        link: '/archives',
        // submenu: [
        //     {
        //         text: 'List Tenants',
        //         link: '/tenant-managment/',
        //     },
        //     {
        //         text: 'Create Tenant',
        //         link: '/tenant-managment/create-tenant',
        //     },
        // ],
    },
    centralPortal: {
        icon: 'bx bxs-log-in ',
        text: 'Central Portal',
        link: '/centralPortal',
        // submenu: [
        //     {
        //         text: 'List Tenants',
        //         link: '/tenant-managment/',
        //     },
        //     {
        //         text: 'Create Tenant',
        //         link: '/tenant-managment/create-tenant',
        //     },
        // ],
    }
};
