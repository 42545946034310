import { Injectable } from "@angular/core";
import { AuthService } from "../auth-services/auth.service";
import { PermissionIds } from "./enum/permissionsIds";

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    private permissionsKeys = [];

    constructor() {
    }

    public setPermissionsKeys(permissionsKeys: string[]) {
        this.permissionsKeys = permissionsKeys;
    }

    public HasPermission(id: string) {
        try{  
            let parentPermissionId = id.split('S')[0];
            let hasValiPermissionLeve11 = this.permissionsKeys.find(p => p === PermissionIds[PermissionIds.PS]);
            let hasValiPermissionLevel2 = this.permissionsKeys.find(p => p === PermissionIds[PermissionIds.PT]);
            let hasValiPermissionLevel3 = this.permissionsKeys.find(p => p === id || p === parentPermissionId);

            if (hasValiPermissionLeve11 || hasValiPermissionLevel2 || hasValiPermissionLevel3) {
                return true;
            }else{
                return false;
            }
        } catch (ex) {
            //console.log(ex);
            return false;
        }
    }
    public HasApplicationAccess() {
        try{  
            let hasValiPermissionLeve11 = this.permissionsKeys.find(p => p === PermissionIds[PermissionIds.PS]);
            let hasValiPermissionLevel2 = this.permissionsKeys.find(p => p === PermissionIds[PermissionIds.PT]);
            let hasValiPermissionLevel3 = this.permissionsKeys.find(p => p === PermissionIds[PermissionIds.P7] || p.split('S')[0] == PermissionIds[PermissionIds.P7]);

            if (hasValiPermissionLeve11 || hasValiPermissionLevel2 || hasValiPermissionLevel3) {
                return true;
            }else{
                return false;
            }
        } catch (ex) {
            //console.log(ex);
            return false;
        }
    }
}