import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { HttpStatus } from 'src/app/shared/enums/http-status.enum';
import { TenantCofigurationsResponse } from 'src/app/shared/models/req-res-models/tenant-configuration.model';
import { environment } from 'src/environments/environment';
import { TenantAPIHandler } from '../../api/handlers/tenant.api.handler';
import { CookieServices } from '../cookie-service/cookieService';
import { TenantConfig } from './models/tenant-config.model';
import { TenantConfigStore } from './stores/tenant-config.store';


@Injectable()
export class TenantConfigService extends TenantConfigStore {

    constructor(private tenantAPIHandler: TenantAPIHandler,private cookiesService: CookieServices
        ) {
        super();
    }

    getTenantConfigurations(tenantName: string): Promise<any> {

            return new Promise((resolve, reject) => {
                return this.tenantAPIHandler.getTenantConfigurations(tenantName).then((data: TenantCofigurationsResponse) => {
                    let tenant = new TenantConfig();
                    //console.log(data);
                    tenant.basePath = data.basePath;
                    tenant.logo = data.logo;
                    tenant.favIcon = data.favIcon;
                    tenant.colors = data.colors;
                    tenant.clientId = data.clientId;
                    tenant.tenantId = data.tenantId;
                    this.cookiesService.setBasePath(data.basePath);
                    super.setCompleteState(tenant);
                    resolve(tenant);
                }).catch(err => {
                    //console.log(err);
                    reject(null);
                });
            });
    }

    ValidateTenant(tenantName: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            return this.getTenantConfigurations(tenantName).then(data => {
                resolve(true);
            }).catch(err => {
                window.location.href = environment.centralPortalUrl;
                reject(false);
            });
        });
    }
    getBasePath() {
        return super.tenantState;
    }

    setTenant(tenant: TenantConfig) {
        return super.setCompleteState(tenant);
    }
    getTenant() {
        return super.tenantState;
    }


}