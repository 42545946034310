import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as MomentAll from "moment";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookieServices {
  
  private basePath = null;
  private domain = null;
  private basePathName = null;
  private redirectTo = null;

  constructor(private _cookieService: CookieService) {
      this.domain = environment.domain;
      this.basePathName = 'bPath';
      this.redirectTo = "redirectTo-"+environment.env;
  }


  private getAccessTokenName(){
      return "accessToken-"+this.getBasePath()+"-"+ environment.env;
  }
  
  private getRefreshTokenName(){
      return "refreshToken-"+this.getBasePath()+"-"+ environment.env;
  }
  
  private getRedirectToName(){
      return "redirectTo-"+this.getBasePath()+"-"+ environment.env;
  }

  private getClientIdName() {
      return "id-"+this.getBasePath()+"-"+ environment.env;
  }

  public setBasePath(basePath){
      this.basePath = basePath;
      this._cookieService.set(this.basePathName, basePath, { domain: this.domain, path: '/' });
  }

  private getBasePath(){
      if(this.basePath){
          return this.basePath;
      }else{
         const basePath=  this._cookieService.get(this.basePathName);
         //console.log(basePath)
         return basePath;
      }
  }

  public getAccessToken(): string {
    try{
      const accessTokenName = this.getAccessTokenName();
      if (!this._cookieService.get(accessTokenName)) {
        return null;
      } else {
        return this._cookieService.get(accessTokenName);
      }
    }catch(ex){
      return null;
    }
  }
  public getRefreshToken(): string {
    try {
      const refreshTokenName = this.getRefreshTokenName();
      if (!this._cookieService.get(refreshTokenName)) {
        return null;
      } else {
        return this._cookieService.get(refreshTokenName)
      }
    } catch (ex) {
      return null;
    }
  }

  public isAuthenticated() {
    try {
      const accessTokenName = this.getAccessTokenName();
      if (!this._cookieService.get(accessTokenName)) {
        return false;
      } else {
        return true;
      }
    } catch (ex) {
      return false;
    }
  }

  public setAccessToken(accessToken: string) {
    const accessTokenName = this.getAccessTokenName();
    let accessTokenExpire = MomentAll().add(1,'h').toDate();
    this._cookieService.set(accessTokenName, accessToken,accessTokenExpire,"/",environment.domain);
  }

  public setRefreshToken(refreshToken: string) {
    const refreshTokenName = this.getRefreshTokenName();
    let refreshTokenExpire = MomentAll().add(2,'d').toDate();
    this._cookieService.set(refreshTokenName, refreshToken,refreshTokenExpire,"/",environment.domain);
  }

  public destroyTokens() {
    try {
      const refreshTokenName = this.getRefreshTokenName();
      const accessTokenName = this.getAccessTokenName();
      this._cookieService.delete(refreshTokenName ,"/",environment.domain);
      this._cookieService.delete(accessTokenName ,"/",environment.domain);
    } catch (ex) {
      return false;
    }
  }

  public setRedirectUrl() {
    try {
        const redirectUrlName = this.getRefreshTokenName();
        const url = decodeURIComponent(window.location.href);
        this._cookieService.set(redirectUrlName, url, null,"/",environment.domain);
    } catch (ex) {
        //console.log(ex);
    }
  }
}
