import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ActionAlertService } from 'src/app/core/services/action-alert.service/action-alert.service';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

  blockedPanel = false;
  basicLoader = false;
  actionAlertSubscribtion: Subscription;

  constructor(public actionAlertService: ActionAlertService, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.actionAlertSubscribtion = this.actionAlertService.alertState.subscribe(status => {
      if (status.isLoading === true || status.isLoading === false) {
        this.blockedPanel = status.isLoading;
      }
      if (status.toast) {
        const message = status.toast.message;
        this.messageService.add({ key: 'toastAlert', severity: status.toast.type, summary: status.toast.summary, detail: message });
        this.actionAlertService.clearMessagers();
      }
    });
  }

  onClose() {
    this.messageService.clear('toastAlert');
  }
  ngOnDestroy() {

    this.actionAlertSubscribtion.unsubscribe();
  }
}
