<!-- <sb-top-nav></sb-top-nav>
<app-sidebar (collapsedEvent)="receiveCollapsed($event)"></app-sidebar>
<section [ngClass]="{ collapsed: collapedSideBar }" class="main-container route-area col-lg-12">
    <router-outlet></router-outlet>  
</section> -->

<section class="bg-gray-a">
    <sb-top-nav></sb-top-nav>
    <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
            <sb-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems" [sideNavSections]="sideNavSections"></sb-side-nav></div>
        <div id="layoutSidenav_content">
            <main>
                <!-- <div class="container"> -->
                    <router-outlet></router-outlet>
                <!-- </div> -->
            </main>
            <!-- <sb-footer></sb-footer> -->
        </div>
    </div>
    <app-alert></app-alert>
</section>