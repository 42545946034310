import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {NavigationService} from "src/app/core/services/navigation/navigation.service";
import {SideNavService} from "src/app/core/services/navigation/side-nav.service";
import {UserService} from "src/app/core/services/navigation/user.service";
import {SideNavComponent} from "./containers/side-nav/side-nav.component";
import {TopNavComponent} from "./containers/top-nav/top-nav.component";
import {BreadcrumbsComponent} from "./sub-components/breadcrumbs/breadcrumbs.component";
import {DashboardHeadComponent} from "./sub-components/dashboard-head/dashboard-head.component";
import {FooterComponent} from "./sub-components/footer/footer.component";
import {SideNavItemComponent} from "./sub-components/side-nav-item/side-nav-item.component";
import {SimplifiedHeaderComponent} from "./sub-components/simplified-header/simplified-header.component";
import {TopNavUserComponent} from "./sub-components/top-nav-user/top-nav-user.component";
import {MenuModule} from "primeng/menu";

export const services = [NavigationService, SideNavService, UserService];
export const containers = [TopNavComponent, SideNavComponent];
export const subComponents = [
    BreadcrumbsComponent,
    SideNavItemComponent,
    FooterComponent,
    DashboardHeadComponent,
    SimplifiedHeaderComponent,
    TopNavUserComponent,
    ]

// const primeNg = [MenuModule];
@NgModule({
    imports: [CommonModule, RouterModule, MenuModule],
    providers: [services],
    declarations: [containers, subComponents],
    exports: [containers, subComponents],
})
export class NavigationModule { }
