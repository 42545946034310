import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class Store<StateType = any> {

    private stateSubject: BehaviorSubject<StateType>;

    constructor(initialState: StateType) {
        this.stateSubject = new BehaviorSubject(initialState);
    }

    getState(): Observable<StateType> {
        return (this.stateSubject.pipe(distinctUntilChanged()));
    }

    getStateSnapshot(): StateType {
        return (this.stateSubject.getValue());
    }

    select<K extends keyof StateType>(key: K): Observable<StateType[K]> {
        const selectStream = this.stateSubject.pipe(
            map(
                (state: StateType) => {
                    return (state[key]);
                }
            ),
            distinctUntilChanged()
        );
        return (selectStream);
    }

    setState(partialState: Partial<StateType>): void {
        const currentState = this.getStateSnapshot();
        const nextState = Object.assign({}, currentState, partialState);
        this.stateSubject.next(nextState);
    }

    clearState() {
        const currentState = this.getStateSnapshot();
        const clearState = Object.entries(currentState).forEach(
            ([key]) => currentState[key] = null
        );
        const nextState = Object.assign({}, currentState, clearState);
        this.stateSubject.next(nextState);
    }
}