<nav [ngClass]="sidenavStyle" class="sb-sidenav accordion" id="sidenavAccordion">
    <div class="sb-sidenav-menu sidenav-light">
        <div class="nav pt-2 pl-2 pr-3">
            <ng-container *ngFor="let section of sideNavSections">
                <!--                <div *ngIf="section.text" class="sb-sidenav-menu-heading">{{ section.text }}</div>-->
                <ng-container *ngFor="let item of section.items">
                    <sb-side-nav-item [sideNavItem]="sideNavItems[item]" (click)="navigate(item)"
                        [isActive]="(item==selectedLink? true:false)"></sb-side-nav-item>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <!-- <div class="sb-sidenav-footer">
        <div class="small">Logged in as:</div>
        <span *ngIf="userService.user$ | async as user">{{user.firstName}} {{user.lastName}}</span>
    </div> -->
</nav>