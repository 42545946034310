import { Injectable } from '@angular/core';
import { Auth } from 'src/app/core/services/auth-services/models/auth.model';
import { Store } from 'src/app/shared/models/store.model';
import { TenantConfig } from '../models/tenant-config.model';

@Injectable({
    providedIn: 'root'
})
export class TenantConfigStore {

    private state: Store<TenantConfig>;
    constructor() {
        this.state = new Store<TenantConfig>({
            logo:  null,
            favIcon: null,
            clientId: null,
            basePath: null,
            colors: {},
            tenantId: null,
            tenantName: null
        });
    }
    public get tenantState() {
        return this.state.getState();
    }

    get stateSnapshot() {
        return this.state.getStateSnapshot();
    }
    protected setCompleteState(tenant: TenantConfig) {
        this.state.setState({
            basePath: tenant.basePath,
            logo: tenant.logo,
            favIcon: tenant.favIcon,
            clientId: tenant.clientId,
            colors: tenant.colors,
            tenantId: tenant.tenantId,
            tenantName: tenant.tenantName
        });
        //console.log(this.state);
    }
}