import { environment } from 'src/environments/environment';

export const ApiConfigs = {
    TenantConfigurations: environment.backendUrl + 'umm/tenants/TENANTBASEPATH/configurations',
    AuthRequest: environment.backendUrl + 'auth/users/authenticate',
    VerifyUserRequest: environment.backendUrl + 'auth/users/verify',
    CreateClassRequest: environment.backendUrl + 'crm/classroom',
    GetClassRoomsRequest: environment.backendUrl + 'crm/classrooms',
    GetClassActivitesRequest: environment.backendUrl + 'crm/classrooms/CLASSID/activities',
    GetClassRoomsByIdRequest: environment.backendUrl + 'crm/classrooms/CLASSID',
    ClassRoomsArchive: environment.backendUrl + 'crm/classrooms/CLASSID/archive',
    ChangeClassRoomsVisibility: environment.backendUrl + 'crm/classrooms/visibility/CLASSID',
    DeleteClassRoom: environment.backendUrl + 'crm/classrooms/CLASSID',
    GetClassRoomsVisibilityDetails: environment.backendUrl + 'crm/classrooms/visibility/CLASSID',
    GetActivitiesForClassRoomsRequest: environment.backendUrl + 'crm/classrooms/CLASSID/activities',
    GetSessionsForActivityRequest: environment.backendUrl + 'crm/classrooms/CLASSID/activities/ACTIVITYID/sessions',
    CreateActivityForClasses: environment.backendUrl + 'crm/activity',
    GetStudentsForClass: environment.backendUrl + 'student/classrooms/CLASSID',
    GetPaymentPlansForClass: environment.backendUrl + 'crm/classrooms/CLASSID/paymentplans',
    UpdatePaymentPlan: environment.backendUrl + 'crm/classrooms/CLASSID/paymentplans/PPID',
    GetPaymentsForClass: environment.backendUrl + 'crm/classrooms/payments',
    GetPaymentProofs: environment.backendUrl + 'crm/classrooms/paymentproofs',
    CheckPaymentReferenceNumber: environment.backendUrl + 'crm/classrooms/checkreferencenumber/PAYMENTREFNUMBER',
    UpdatePaymentProofs: environment.backendUrl + 'crm/classrooms/paymentproofs',
    UpdatePaymentsForClass: environment.backendUrl + 'crm/classrooms/payments',
    ArchiveClassActivity: environment.backendUrl + 'crm/classrooms/CLASSID/activities/ACTIVITYID/archive',
    VisibilityClassActivity: environment.backendUrl + 'crm/classrooms/CLASSID/activities/ACTIVITYID/visibility',
    DeleteClassActivity: environment.backendUrl + 'crm/classrooms/CLASSID/activities/ACTIVITYID',
    GetClassNotificationTemplates :  environment.backendUrl + 'crm/classrooms/CLASSID/notificationtemplates',
    AddClassNotificationTemplates :  environment.backendUrl + 'crm/classrooms/notificationtemplates',
    UpdateClassNotificationTemplates :  environment.backendUrl + 'crm/classrooms/CLASSID/notificationtemplates/TEMPLATEID',
    SendClassNotification :  environment.backendUrl +'crm/classrooms/notifications'
}

export const RouteParams = {
    TenantBasePath : 'TENANTBASEPATH',
    PaymentPlanID: 'PPID',
    ClassId:'CLASSID',
    PaymentID: 'PAYMENTID',
    ActivityId: 'ACTIVITYID',
    TemplateId: 'TEMPLATEID',
    PaymentRefNumber: 'PAYMENTREFNUMBER'
}

export function replacePathValue(path, replaceString, value): string {
   
    let replacedPath = path.split(replaceString).join(value);
    return replacedPath;
}
