import { DOCUMENT } from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import { AuthService } from 'src/app/core/services/auth-services/auth.service';
import { CookieServices } from 'src/app/core/services/cookie-service/cookieService';
import {NavigationService} from 'src/app/core/services/navigation/navigation.service';
import {TenantConfigService} from 'src/app/core/services/tenant-config-services/tenant-config.service';
import { environment } from 'src/environments/environment';
// import { RouteControllerService } from 'src/app/core/helpers/route-controller.service';
// import { AuthFacade } from 'src/app/core/state/auth/auth.facade.service';

@Component({
    selector: 'sb-top-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
    items: MenuItem[];
    user_items: MenuItem[];
    status: boolean;
    tenantName: string;
    logo: string;
    constructor(
        private navigationService: NavigationService,
        public tenantService : TenantConfigService,
        private cookieService : CookieServices,
        private authService: AuthService,
        @Inject(DOCUMENT) private document: Document,
        ) { }
    ngOnInit() { 

        this.tenantService.getTenant().subscribe(tenant => {
            //console.log(tenant.basePath);
            this.tenantName = tenant.basePath;
            this.logo = tenant.logo;
        });

        this.user_items = [
            {
                label: 'My Profile',
                icon: 'bx bx-user',
                // please change the url to navigate user profile ui properly
                routerLink: ['user-profile']
            },
            {
                separator: true
            },
            {
                label: 'Sign Out',
                icon: 'bx bx-log-out-circle',
                command: () => this.signOut()
            }
        ];
    }
    toggleSideNav() {
        this.navigationService.toggleSideNav();
        this.items = [
            {
                label: 'File',
                items: [{
                    label: 'New',
                    icon: 'bx bx-envelope',
                    items: [
                        { label: 'Project' },
                        { label: 'Other' },
                    ]
                },
                { label: 'Open' },
                { label: 'Quit' }
                ]
            },
            {
                label: 'Edit',
                icon: 'bx bx-envelope',
                items: [
                    { label: 'Delete', icon: 'bx bx-envelope' },
                    { label: 'Refresh', icon: 'bx bx-envelope' }
                ]
            }
        ];
    }

    signOut(){
        this.cookieService.destroyTokens();
        this.goTocentralPortal();
    }
    
    goTocentralPortal() {
        this.document.location.href = environment.centralPortalUrl + this.tenantName;
    }
}
